import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { type ReactElement } from "react";

import { DocumentDetailsChecklistItems } from "./ChecklistItems";
import { DocumentDetailsSection } from "./Section";

interface DocumentDetailsContentProps {
  description: string;
  instructions?: string;
  fallbackStaticUrl?: string;
  checklist: {
    enabled: boolean;
    description?: string;
    items: string[];
  };
}

export function DocumentDetailsContent(props: DocumentDetailsContentProps): ReactElement {
  const { description, instructions, checklist, fallbackStaticUrl } = props;
  return (
    <Stack spacing={3}>
      {isDefined(description) && !isEmpty(description) && (
        <DocumentDetailsSection title="Description" description={description} />
      )}
      {isDefined(instructions) && !isEmpty(instructions) && (
        <DocumentDetailsSection title="Instructions" description={instructions} />
      )}
      {isDefined(fallbackStaticUrl) && !isEmpty(fallbackStaticUrl) && (
        <ExternalLink to={fallbackStaticUrl}>
          <Text variant="h3" color="secondary">
            Click to sign document
          </Text>
        </ExternalLink>
      )}
      {checklist?.enabled && isDefined(checklist?.description) && checklist.items.length > 0 && (
        <DocumentDetailsSection title="Checklist" description={checklist.description}>
          <DocumentDetailsChecklistItems items={checklist.items} />
        </DocumentDetailsSection>
      )}
    </Stack>
  );
}
